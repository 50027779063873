"use client";
import React, { HTMLInputTypeAttribute, LegacyRef } from "react";

type Props = {
  inputId: string,
  inputLabel?: any,
  inputPlaceholder?: string,
  inputType?: HTMLInputTypeAttribute | undefined | "textarea",
  required?: boolean,
  minLength?: number,
  maxLength?: number,
  value?: any,
  hidden?: boolean,
  list?: string,
  min?: number,
  max?: number,
  pattern?: string,
  validCharacters?: string,
  reference?: LegacyRef<HTMLInputElement>,
  onChange?: (event: React.ChangeEvent<any>) => void,
  onBlur?: (event: React.FocusEvent<any>) => void,
  onFocus?: (event: React.FocusEvent<any>) => void,
  onKeyDown?: (event: React.KeyboardEvent<any>) => void,
};

export default function FormGroupInput(props: Readonly<Props>) {
  let onKeyDown = props.onKeyDown;
  let pattern = props.pattern;
  let validCharacters = props.validCharacters;
  let maxLength = props.maxLength;

  if (props.inputType == "number") {
    if (pattern === undefined) {
      pattern = props.validCharacters == "1234567890" ? "^[0-9]*$" : "^[0-9,.]*$";
    }
    if (validCharacters === undefined) {
      validCharacters = "1234567890,.";
    }
    if (maxLength === undefined && props.max !== undefined) {
      maxLength = (Math.max(0, props.max - 1)).toString().length;
    }
  }

  if (onKeyDown === undefined && validCharacters !== undefined) {
    onKeyDown = (e: React.KeyboardEvent<any>) => {
      if (e.metaKey || e.altKey || e.ctrlKey) {
        return;
      }
      switch (e.key) {
        case "Tab":
        case "Shift":
        case "Backspace":
        case "Delete":
        case "ArrowLeft":
        case "ArrowRight":
        case "ArrowUp":
        case "ArrowDown":
        case "Enter":
        case "Home":
        case "End":
          return;
      }
      if (!validCharacters.includes(e.key)) {
        e.preventDefault();
      } else if (maxLength !== undefined && e.currentTarget.value !== undefined && e.currentTarget.value.length >= maxLength) {
        e.preventDefault();
      }
    };
  }

  const hidden = props.hidden || props.inputType === "hidden";
  return (
    <div className={"form-group"} hidden={hidden}>
      <label htmlFor={props.inputId} className={"form-label"} hidden={!props.inputLabel}>
        {props.inputLabel} {props.required ? "*" : ""}
      </label>
      {props.inputType === "textarea" ?
        (
          <textarea
            className="form-control"
            id={props.inputId}
            name={props.inputId}
            rows={8}
            placeholder={props.inputPlaceholder || ""}
            required={props.required}
            minLength={props.minLength}
            maxLength={maxLength}
            defaultValue={props.value}
            onChange={props.onChange}
            onBlur={props.onBlur}
            onKeyDown={onKeyDown}
          />
        ) : (
          <input
            onKeyDown={onKeyDown}
            onFocus={props.onFocus}
            min={props.min}
            max={props.max}
            onBlur={props.onBlur}
            list={props.list}
            type={props.inputType || "text"}
            className="form-control"
            name={props.inputId}
            id={props.inputId}
            pattern={pattern}
            placeholder={props.inputPlaceholder || ""}
            required={props.required}
            minLength={props.minLength}
            maxLength={maxLength}
            defaultValue={props.value}
            ref={props.reference}
            onChange={props.onChange}
          />
        )
      }
    </div>
  );
}
