import useUserProfileContext from "@/components/global/helper/UserProfileProvider/useUserProfileContext";
import { useEffect, useState } from "react";
import { Props } from "./CompetitionOverviewPage";

export default function useCompetitionOverviewPageController(
  props: Readonly<Props>
) {
  const {selectedOrganization } = useUserProfileContext();
  const selectedOrganizationId = selectedOrganization?.id;
  const [isCreateCompetitionEnabled, setCreateCompetitionEnabled] =
    useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    refreshOrganizationRights();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOrganizationId]);

  async function refreshOrganizationRights() {
    if (selectedOrganizationId) {
      const isCreateCompetitionEnabled =
        await props.checkRightToCreateCompetitionFunction(
          selectedOrganizationId
        );
      setCreateCompetitionEnabled(isCreateCompetitionEnabled);
    } else {
      setCreateCompetitionEnabled(false);
    }
  }

  return {
    isCreateCompetitionEnabled,
    isLoading,
    setLoading,
  };
}
