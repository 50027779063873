import "./ToolTip.css";

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

type Props = {
  id?: string;
  text: string | undefined;
  children: any;
  placement?: "top" | "right" | "bottom" | "left";
  showDelay?: number;
  hideDelay?: number;
};

export default function ToolTip(props: Readonly<Props>) {
  if (props.text == undefined || props.text.trim().length == 0) {
    return props.children;
  }

  return (
    <OverlayTrigger
      placement={props.placement ?? "top"}
      delay={{ show: props.showDelay ?? 400, hide: props.hideDelay ?? 250 }}
      overlay={
        <Tooltip id={props.id}>
          {props.text}
        </Tooltip>
      }
    >
      {props.children}
    </OverlayTrigger>
  );
}
