"use client";
import AppIcon from "@/components/global/icons/AppIcon";
import Link from "next/link";
import { useState } from "react";
import { Variant } from "react-bootstrap/types";
import LoadingIndicator from "../FullScreenOverlay/LoadingIndicator";
import "./icon-link.css";
import ToolTip from "@/components/common/ToolTip";

type Props = {
  id: string;
  icon: AppIcon;
  variant?: Variant;
  href?: string;
  onClick?: (e?: any) => any;
  tooltip?: string;
  tooltipPlacement?: "top" | "bottom" | "left" | "right";
  showLoadingIndicatorOnClick?: boolean;
  hidden?: boolean;
  disabled?: boolean;
};
export default function IconLink(props: Readonly<Props>) {
  const [isLoading, setLoading] = useState<boolean>(false);

  const onClick = (e: any) => {
    if (props.showLoadingIndicatorOnClick) {
      setLoading(true);
    }
    if (props.onClick) {
      props.onClick(e);
    }
  };

  if(props.hidden) {
    return null;
  }

  if (isLoading) {
    return <LoadingIndicator/>;
  }

  if (props.disabled) {
    return <span className="d-print-none icon-link disabled">{props.icon}</span>;
  }

  const properties = {
    hidden: props.hidden,
    id: props.id,
    className: `d-print-none my-2 my-sm-0 icon-link ${("icon-link-" + (props.variant || "primary"))}`,
    href: props.href ?? "",
    onClick: onClick,
    children: props.icon
  }

  return (
    <ToolTip id={props.id} text={props.tooltip} placement={props.tooltipPlacement}>
      {props.href ? <Link {...properties} /> : <span {...properties} />}
    </ToolTip>
  );
}
